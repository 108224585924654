import { apiCallback } from 'api';

import query from 'api/definitions/slotdates/slotdates.graphql';
import { SlotDatesInput } from './types';

const definition = {
  graph: true,
  query: () => query,
  variables: ({
    addressId,
    branchId,
    slotType,
    customerOrderId,
    fromDate,
    size,
  }: SlotDatesInput) => ({
    slotDatesOnlyInput: {
      addressId,
      branchId,
      customerOrderId,
      fromDate,
      size,
      slotType,
    },
    filedSlotTimesInput: {
      addressId,
      branchId,
      fromDate,
      size,
      slotType,
    },
  }),
  queryString: { tag: 'slot-dates' },
};

export default {
  post: apiCallback(definition, 'post'),
};
