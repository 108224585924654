import { apiCallback } from 'api';

const definition = {
  service: 'slot-experience',
  endpoint: 'slot-messages',
  verbs: ['get'],
  defaultVersion: 1,
  query: {
    slotsPage: '${slotsPage}',
    slotType: '${slotType}',
    branchId: '${branchId}',
    addressId: '${addressId}',
  },
};

export default {
  get: apiCallback(definition, 'get'),
};
