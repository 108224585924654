import { SlotDate, SlotGridType } from 'api/definitions/slotdates/types';
import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';
import { getSelectedSlotGridType } from 'redux/modules/slot-booking/selectors/get-selected-slot-grid-type';
import { createSelector } from 'reselect';

const EMPTY_SLOT_DATES: SlotDate[] = [];

export const getSlotDates = (state: WtrState) => state?.slotDates?.dates ?? EMPTY_SLOT_DATES;
export const hasSlotDates = (state: WtrState) =>
  !!getStateAtNamespaceKey(state, 'slotDates').dates &&
  getStateAtNamespaceKey(state, 'slotDates').dates.length > 0;

export const getFirstSlotDay = (state: WtrState) =>
  getSlotDates(state).length ? getSlotDates(state)[0].date : null;

export const getLastSlotDay = (state: WtrState) => {
  const slotDates = getSlotDates(state);
  return slotDates.length ? slotDates[slotDates.length - 1]?.date : null;
};

export const isSlotDatesLoading = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'slotDates').loading;

export const isPriority = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'slotDates').isPriority;

export const areDatesCampaignDatesOnly = (state: WtrState) => state.slotDates?.hasCampaignDatesOnly;

export const getFiledSlotTimes = (state: WtrState) => {
  const slotGridType =
    getStateAtNamespaceKey(state, 'slotBooking').selectedSlotGridType || SlotGridType.DEFAULT_GRID;
  return (
    getStateAtNamespaceKey(state, 'slotDates').times &&
    getStateAtNamespaceKey(state, 'slotDates').times.find(
      time => time.slotGridType === slotGridType,
    )?.slotTimes
  );
};

export const getSlotGridTypes = createSelector(
  (state: WtrState) => getStateAtNamespaceKey(state, 'slotDates').times,
  slotDateTimes => slotDateTimes.map(time => time.slotGridType).sort((a, b) => a.localeCompare(b)),
);

export const hasSelectedSlotGridType = createSelector(
  getSlotGridTypes,
  getSelectedSlotGridType,
  (slotGridTypes = [], selectedSlotGridType) => slotGridTypes.includes(selectedSlotGridType),
);
