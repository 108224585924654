import { createSelector } from 'reselect';
import {
  FORMAT,
  dayjs,
  isBefore,
  isSameDay,
  addDays,
  isWithinRange,
  startOfDay,
  format,
} from 'utils/date';

import { getProducts } from 'redux/modules/entities/selectors/products';
import { getTrolleyItems } from 'redux/modules/trolley/selectors/get-items';
import { getFirstSlotDay, areDatesCampaignDatesOnly } from 'redux/modules/slot-dates/selectors';

const normaliseAvailableDateRanges = item => {
  if (item?.product?.restriction?.availableDates?.length) {
    const availableDates = item.product.restriction.availableDates.map(
      ({ startDate, endDate }) => ({
        startDate: format(startDate, FORMAT.ISO8601_DATE),
        endDate: format(endDate, FORMAT.ISO8601_DATE),
      }),
    );

    return {
      ...item,
      product: {
        ...item.product,
        restriction: {
          ...item.product.restriction,
          availableDates,
        },
      },
    };
  }

  return item;
};

export const getTrolleyItemsAndProducts = createSelector(
  [getTrolleyItems, getProducts],
  (trolleyItems, products) =>
    trolleyItems.map(item => ({
      trolleyItem: item,
      product: products[item.lineNumber],
    })),
);

const getTrolleyItemsAndProductsWithLeadTimes = createSelector(
  [getTrolleyItemsAndProducts],
  items => items.filter(({ product }) => product?.leadTime > 0),
);

export const getTrolleyItemsAndProductsWithAvailableDates = createSelector(
  [getTrolleyItemsAndProducts],
  items => items.filter(({ product }) => product?.restriction?.availableDates?.length > 0, []),
);

export const getItemsAndProductsWithRestrictions = createSelector(
  [getTrolleyItemsAndProductsWithLeadTimes, getTrolleyItemsAndProductsWithAvailableDates],
  (leadTimes, availableDates) =>
    [...leadTimes, ...availableDates].reduce((productsWithRestrictions, item) => {
      if (
        !productsWithRestrictions.find(
          existing => existing.trolleyItem?.lineNumber === item.trolleyItem?.lineNumber,
        )
      ) {
        const normalisedItem = normaliseAvailableDateRanges(item);
        productsWithRestrictions.push(normalisedItem);
      }
      return productsWithRestrictions;
    }, []),
);

export const getProductsNotAvailableOnDate = (state, date) => {
  const products = getItemsAndProductsWithRestrictions(state);
  const today = new Date();
  return products.filter(({ product }) => {
    const leadTimeDate = addDays(startOfDay(today), product?.leadTime);
    if (isBefore(date, leadTimeDate)) {
      return true;
    }
    const availableDateRanges = product?.restriction?.availableDates;
    return availableDateRanges?.every(
      ({ startDate, endDate }) => !isWithinRange(date, startDate, endDate),
    );
  });
};

export const getRestrictionDateRangeByDate = (state, date) => {
  const products = getItemsAndProductsWithRestrictions(state);

  return products.reduce(
    (dateRange, { product }) => {
      const availableDateRanges = product?.restriction?.availableDates;
      const relevantRange = availableDateRanges?.find(({ startDate, endDate }) =>
        isWithinRange(date, startDate, endDate),
      );
      return relevantRange || dateRange;
    },
    { startDate: null, endDate: null },
  );
};

export const getEarliestRestrictionDateBetweenDates = (products, proposedDate, selectedDate) =>
  products?.reduce((earliestDate, { product }) => {
    if (product?.restriction?.availableDates?.length) {
      const day = product.restriction.availableDates.find(({ startDate }) =>
        isWithinRange(startDate, proposedDate, selectedDate),
      )?.startDate;
      if (!earliestDate || isBefore(day, earliestDate)) {
        return day;
      }
    }
    if (product?.leadTime) {
      const day = startOfDay(addDays(new Date(), product?.leadTime));
      if (
        isWithinRange(day, proposedDate, selectedDate) &&
        (!earliestDate || isBefore(day, earliestDate))
      ) {
        return format(day, FORMAT.ISO8601_DATE);
      }
    }
    return earliestDate;
  }, null);

export const getLatestRestrictionDateBetweenDates = (products, proposedDate, selectedDate) =>
  products.reduce((latestDate, { product }) => {
    const day = product?.restriction?.availableDates?.find(({ endDate }) =>
      isWithinRange(endDate, selectedDate, proposedDate),
    )?.endDate;
    if (day && (!latestDate || isBefore(latestDate, day))) {
      return day;
    }

    return latestDate;
  }, null);

export const hasItemsWithEarlierRestrictions = createSelector(
  [getItemsAndProductsWithRestrictions, getFirstSlotDay, areDatesCampaignDatesOnly],
  (restrictedProducts, firstSlotDay, hasCampaignDatesOnly) => {
    const today = new Date();
    if (!restrictedProducts.length || !firstSlotDay || isBefore(firstSlotDay, today)) {
      return false;
    }
    const earliestDate = getEarliestRestrictionDateBetweenDates(
      restrictedProducts,
      today,
      firstSlotDay,
    );
    return (
      earliestDate &&
      !hasCampaignDatesOnly &&
      dayjs(earliestDate).isSameOrBefore(firstSlotDay) &&
      !isSameDay(today, earliestDate)
    );
  },
);
