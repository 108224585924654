import { isBefore, parse } from 'utils/date';
import { createSelector } from 'reselect';
import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';
import { getFirstSlotDay } from 'redux/modules/slot-dates/selectors';

export const getSelectedDate = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'slotBooking').selectedDate;

export const isSelectedDateBeforeAvailableSlotDaysRange = createSelector(
  [getSelectedDate, getFirstSlotDay],
  (selectedDate, firstSlotDate) =>
    !!selectedDate && !!firstSlotDate && isBefore(parse(selectedDate), firstSlotDate),
);
