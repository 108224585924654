import { createSelector } from 'reselect';
import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';

const getAddresses = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'customerAddress').addresses;
const getSelectedAddress = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'slotBooking').selectedAddress;

export const getSelectedAddressId = createSelector(
  getSelectedAddress,
  selectedAddress => selectedAddress?.id,
);

export const getSortedAddresses = createSelector(
  [getAddresses, getSelectedAddress],
  (addresses, selectedAddress) =>
    [...addresses].sort((address1, address2) => {
      if (selectedAddress && address1.id === selectedAddress.id) {
        return -1;
      }
      if (selectedAddress && address2.id === selectedAddress.id) {
        return 1;
      }

      return 0;
    }),
);

export const getEnteredDeliveryPostcode = (state: WtrState) => {
  const { selectedAddress } = getStateAtNamespaceKey(state, 'slotBooking');
  if (selectedAddress && selectedAddress.id === '-1') {
    return selectedAddress.postalCode;
  }
  return '';
};
