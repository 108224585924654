import { SlotGridType } from 'api/definitions/slotdates/types';
import { FORMAT, dayjs } from 'utils/date';
import { noSlotDatesModal } from 'constants/modals';
import { pushIsSaverSlotBranchToGTMDataLayer } from 'utils/gtm';
import history from 'utils/history';
import { rootPath } from 'utils/root-path';
import { Branch } from 'redux/modules/branches/state/initial-state';
import { setSelectedBranch } from 'redux/modules/slot-booking/actions/set-selected-branch';
import { setSelectedDate } from 'redux/modules/slot-booking/actions/set-selected-date';
import { getSlotDates as getSlotDatesAsync } from 'redux/modules/slot-dates/actions/get-slot-dates';
import {
  getFirstSlotDay,
  getSlotDates,
  getSlotGridTypes,
} from 'redux/modules/slot-dates/selectors';
import { loadSlots } from 'redux/modules/slot-booking/actions/load-slots';
import {
  getSelectedDate,
  isSelectedDateBeforeAvailableSlotDaysRange,
} from 'redux/modules/slot-booking/selectors/get-selected-date';
import { getFulfilmentType } from 'redux/modules/slot-booking/selectors/get-fulfilment-type';
import { getDefaultCarouselDate } from 'redux/modules/slot-booking/selectors/get-default-carousel-date';
import {
  getFromDateFromUrl,
  getStartDateFromUrl,
} from 'redux/modules/slot-booking/utils/date-url-params';
import { openModal } from 'redux/modules/common-modal/actions';

import { isSlotsDisabledSelector } from 'redux/modules/slots/selectors/slots-disabled';
import { getCampaignByDate } from 'redux/modules/slot-dates/selectors/campaigns';
import { setCampaignDatesAsSlotDates } from 'redux/modules/slot-dates/actions/set-campaign-dates-as-slot-dates';
import { getSelectedAddressId } from 'redux/modules/slot-booking/selectors/addresses';
import getSlotExperience from 'redux/modules/slot-experience/actions';
import { FulfilmentCategories } from 'constants/fulfilmentTypes';
import { setSelectedSlotGridType } from './set-selected-slot-grid-type';

export const selectBranchLoadSlots =
  (branch: Branch, noDateReset = false) =>
  async (dispatch: WtrDispatch, getState: () => WtrState) => {
    const fromDateInUrl = getFromDateFromUrl();
    const startDateInUrl = getStartDateFromUrl();
    const fulfilmentType = getFulfilmentType();
    const slotType = fulfilmentType?.slotType;
    const isCollection = fulfilmentType?.category === FulfilmentCategories.COLLECTION;

    if (!slotType) {
      return null;
    }

    dispatch(setSelectedBranch(branch));
    dispatch(getSlotExperience('SLOT_GRID', slotType, branch.id, getSelectedAddressId(getState())));

    if (isCollection) {
      dispatch(setSelectedSlotGridType(SlotGridType.DEFAULT_GRID));
    }

    return (
      dispatch(
        getSlotDatesAsync(
          branch.id,
          getSelectedAddressId(getState()) || '',
          fromDateInUrl || dayjs().format(FORMAT.ISO8601_DATE),
          slotType,
        ),
      ) as unknown as Promise<void>
    )
      .then(() => {
        let state = getState();
        if (isSlotsDisabledSelector(state)) {
          return null;
        }
        if (getSlotDates(state).length) {
          state = getState();
          if (!noDateReset) {
            dispatch(
              setSelectedDate(startDateInUrl || fromDateInUrl || getDefaultCarouselDate(state)),
            );
          } else {
            const date = getSelectedDate(state);
            const firstSlotDay = getFirstSlotDay(state);

            // Even though we're not 'resetting' the date, we still need to ensure we're
            // clamped to the start of the date range
            if (isSelectedDateBeforeAvailableSlotDaysRange(state)) {
              dispatch(setSelectedDate(firstSlotDay));
            } else {
              dispatch(setSelectedDate(date));
            }
          }

          const slotGridTypes = getSlotGridTypes(state);
          const isSaverSlotBranch = slotGridTypes.includes(SlotGridType.SAVER_GRID);
          pushIsSaverSlotBranchToGTMDataLayer(isSaverSlotBranch);

          return dispatch(loadSlots());
        }

        const campaign = getCampaignByDate(state, new Date(), false);
        if (campaign) {
          dispatch(setSelectedBranch(branch));
          dispatch(setCampaignDatesAsSlotDates(campaign));
          dispatch(setSelectedDate(getFirstSlotDay(getState())));
          return dispatch(loadSlots());
        }

        return dispatch(
          openModal({
            ...noSlotDatesModal,
            onCloseCallback: () => history.push(rootPath('/shop/trolley')),
          }),
        );
      })
      .catch(() => dispatch(setSelectedDate(null)));
  };
