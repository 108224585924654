import { FORMAT, dayjs } from 'utils/date';

import { SET_CAMPAIGN_DATES_AS_SLOT_DATES } from 'redux/modules/slot-dates/actions/types';
import { Campaign } from 'redux/modules/slot-dates/state/initial-state';

const eachDay = (from: dayjs.ConfigType, to: dayjs.ConfigType) => {
  const dates = [];
  let current = dayjs(from);
  const toDate = dayjs(to);
  while (current.isSameOrBefore(toDate, 'day')) {
    dates.push(current);
    current = current.add(1, 'day');
  }
  return dates;
};

export const setCampaignDatesAsSlotDates = (campaign: Campaign) => (dispatch: WtrDispatch) => {
  if (campaign.seasonalStartDate && campaign.seasonalEndDate) {
    const { seasonalStartDate, seasonalEndDate } = campaign;
    const campaignDates = eachDay(seasonalStartDate, seasonalEndDate).map(day => ({
      date: dayjs(day).format(FORMAT.ISO8601_DATE),
      dayOfWeek: dayjs(day).format(FORMAT.DAY_OF_WEEK),
    }));
    dispatch({
      type: SET_CAMPAIGN_DATES_AS_SLOT_DATES,
      payload: { campaignDates },
    });
  }
};
