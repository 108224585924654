import { apiCallback } from 'api';

import query from 'api/definitions/slotdays/slotdays.graphql';
import { SlotDaysInput } from './types';

const definition = {
  graph: true,
  query: () => query,
  variables: ({
    branchId,
    slotType,
    customerOrderId,
    postcode,
    addressId,
    fromDate,
    size,
    slotGridType,
  }: SlotDaysInput) => ({
    slotDaysInput: {
      branchId,
      slotType,
      customerOrderId,
      postcode,
      addressId,
      fromDate,
      size,
      slotGridType,
    },
  }),
  queryString: { tag: 'slot-days' },
};

export default {
  getSlots: apiCallback(definition, 'post'),
};
