import { openModal } from 'redux/modules/common-modal/actions';
import { saverSlotsUnavailableOnChristmas } from 'constants/modals';
import { SlotGridType } from 'api/definitions/slotdates/types';
import { setSelectedSlotGridType } from './set-selected-slot-grid-type';

export const saverSlotsUnavailableOnChristmasModal =
  (slotGridType: SlotGridType) => async (dispatch: WtrDispatch) => {
    await (new Promise(resolve => {
      dispatch(setSelectedSlotGridType(slotGridType));
      dispatch(
        openModal({
          ...saverSlotsUnavailableOnChristmas,
          onCloseCallback() {
            dispatch(setSelectedSlotGridType(slotGridType));
            resolve();
          },
        }),
      );
    }) as Promise<void>);
  };
