import { createSelector } from 'reselect';

import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';
import { getFeatureFlags } from 'utils/feature-flags';

enum SlotsDisabledStatuses {
  disabled = 'DISABLED',
  deliveryDisabled = 'DELIVERY_DISABLED',
}

const isSlotsAlreadyDisabled = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'slots').slotsDisabled;

const getSlotsDisabledStatus = (state: WtrState) => {
  const {
    slots_allSlotsDisabled: allSlotsDisabled,
    slots_allDeliverySlotsDisabled: allDeliverySlotsDisabled,
  } = getFeatureFlags() || {};

  if (allSlotsDisabled === true) {
    return SlotsDisabledStatuses.disabled;
  }
  if (allDeliverySlotsDisabled === true) {
    return SlotsDisabledStatuses.deliveryDisabled;
  }
  if (isSlotsAlreadyDisabled(state)) {
    return SlotsDisabledStatuses.disabled;
  }
  return false;
};

export const isSlotsDisabledSelector = createSelector(
  [getSlotsDisabledStatus],
  disabledStatus => disabledStatus === SlotsDisabledStatuses.disabled,
);

export const isDeliverySlotsDisabled = createSelector(
  [getSlotsDisabledStatus],
  disabledStatus => disabledStatus === SlotsDisabledStatuses.deliveryDisabled,
);
