import { CampaignName } from 'api/definitions/slotdates/types';
import { endOfDay, isValid, isWithinRange, parse, startOfDay } from 'utils/date';
import { getCustomerSlotDate } from 'redux/modules/customer-slot/selectors';
import { Campaign } from '../state/initial-state';

const NO_CAMPAIGNS: Partial<Record<CampaignName, Campaign>> = {};

export const getCampaigns = (state: WtrState) => state?.slotDates?.campaigns ?? NO_CAMPAIGNS;

export const getCampaignByDate = (
  state: WtrState,
  date: string | Date,
  bookableRangeOnly = false,
) => {
  const campaigns = getCampaigns(state);
  const selectedDate = parse(date);
  return isValid(selectedDate)
    ? Object.values(campaigns).find(campaign => {
        const seasonalStartDate = startOfDay(
          bookableRangeOnly ? campaign.seasonalStartDate : campaign.siteStartDate,
        );
        const seasonalEndDate = endOfDay(
          bookableRangeOnly ? campaign.seasonalEndDate : campaign.siteEndDate,
        );
        if (isWithinRange(selectedDate, seasonalStartDate, seasonalEndDate)) {
          return campaign;
        }
        return false;
      })
    : undefined;
};

export const campaignByCustomerSlotSelector = (state: WtrState) => {
  const slotDate = getCustomerSlotDate(state);
  if (slotDate) {
    return getCampaignByDate(state, slotDate, true);
  }
  return false;
};

export const hasCampaign = (state: WtrState, range: CampaignName) => !!getCampaigns(state)[range];

export const doTheyKnowItsChristmasSelector = (state: WtrState) => {
  if (hasCampaign(state, CampaignName.CHRISTMAS)) {
    const christmasCampaign = getCampaigns(state)[CampaignName.CHRISTMAS];
    if (christmasCampaign) {
      const { siteStartDate, siteEndDate } = christmasCampaign;
      return isWithinRange(new Date(), startOfDay(siteStartDate), endOfDay(siteEndDate));
    }
  }
  return false;
};
