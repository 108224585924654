import trolleyItems from 'api/definitions/trolleyItems';

import {
  REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY,
  REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_SUCCESS,
  REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_FAILED,
} from 'redux/modules/trolley/actions/types';
import { handleUpdateTrolleyErrors } from 'redux/modules/trolley/error-handling';
import { getItemById } from 'redux/modules/trolley/selectors/get-item';

export default removeTrolleyItemsList => (dispatch, getState) => {
  const state = getState();
  const actualTrolleyItems = removeTrolleyItemsList.map(id => getItemById(state, id));
  const removeTrolleyItems = removeTrolleyItemsList.map(trolleyItemId => ({
    trolleyItemId,
    quantity: {
      amount: 0,
      uom: 'C62',
    },
  }));

  dispatch({
    trolleyItems: actualTrolleyItems,
    type: REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY,
  });

  return trolleyItems
    .bulkUpdate({ body: removeTrolleyItems })({ dispatch, getState })
    .then(response => {
      const { data: { updateTrolleyItems } = {}, errors } = response;

      if (errors || !updateTrolleyItems || updateTrolleyItems.failures) {
        let error = new Error('Graph call succeeded but returned no data or an unknown error');
        dispatch({
          error: 'Graph call succeeded but returned no data or an unknown error',
          type: REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_FAILED,
          trolleyItems: actualTrolleyItems,
        });

        if (errors?.length) {
          [error] = errors;
        }

        if (updateTrolleyItems?.failures?.length) {
          const { failures } = updateTrolleyItems;
          [error] = failures;
        }
        return handleUpdateTrolleyErrors(dispatch, getState)(error);
      }

      return dispatch({
        type: REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_SUCCESS,
        trolleyItems: actualTrolleyItems,
      });
    })
    .catch(err => {
      dispatch({
        error: 'Update trolley items call to graph failed',
        type: REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_FAILED,
        trolleyItems: actualTrolleyItems,
      });
      return handleUpdateTrolleyErrors(dispatch, getState)(err);
    });
};
