import slotdays from 'api/definitions/slotdays';
import { handleGenericNetworkError } from 'api/actions/handle-generic-network-error';
import { SlotGridType } from 'api/definitions/slotdates/types';
import { SlotDaysResponse } from 'api/definitions/slotdays/types';
import { FulfilmentType, SlotTypes } from 'constants/fulfilmentTypes';
import { SET_SLOTS_DISABLED, SLOT_DAYS } from 'redux/modules/slots/actions/types';
import { flattenSlots } from 'redux/modules/slot-booking/utils/merge-slots';

export const getSlots =
  ({
    fulfilmentType,
    postcode,
    addressId,
    branchId,
    fromDate,
    size,
    slotGridType,
  }: {
    fulfilmentType: FulfilmentType;
    postcode?: string;
    addressId?: string;
    branchId: string;
    fromDate: string;
    size?: number;
    slotGridType?: SlotGridType;
  }) =>
  (dispatch: WtrDispatch) => {
    const { slotType } = fulfilmentType;

    return dispatch({
      types: SLOT_DAYS.triplet,
      apiCall: slotdays.getSlots({
        fromDate,
        size,
        slotType,
        slotGridType,
        ...(slotType === SlotTypes.DELIVERY ? { addressId, postcode } : { branchId }),
      }),
      callback: {
        success: (res: { data: SlotDaysResponse; errors: Error[] }) => {
          const { data: { slotDays } = {}, errors } = res;

          if (errors || !slotDays || slotDays.failures || !slotDays.content) {
            let error = new Error('Graph call succeeded but returned no data or an unknown error');

            if (errors?.length) {
              [error] = errors;
            }

            if (slotDays?.failures?.length) {
              const { failures } = slotDays;
              error = new Error(JSON.stringify(failures));
            }

            if (error.message.includes('API_UNAVAILABLE')) {
              return dispatch({
                type: SET_SLOTS_DISABLED,
              });
            }

            handleGenericNetworkError({ dispatch, error });
            throw new Error(error && error.message);
          }

          return {
            slots: flattenSlots(res.data, branchId),
            greenVariant: res.data.slotDays.variant,
          };
        },
      },
    });
  };
