import slotExperience from 'api/definitions/slot-experience';
import { SlotPage, SlotType } from 'api/definitions/slot-experience/types';
import { SLOT_EXPERIENCE, SPECIFIC_BRANCH_MESSAGE_PARAMETERS } from './types';

export default (
    slotsPage: SlotPage,
    slotType: SlotType | null,
    branchId?: string,
    addressId?: string,
  ) =>
  (dispatch: WtrDispatch) => {
    const specificBranch: SPECIFIC_BRANCH_MESSAGE_PARAMETERS = {};
    const isDelivery = slotType && ['DELIVERY', 'DELIVERY_SERVICE'].includes(slotType);
    if (isDelivery && addressId) {
      specificBranch.addressId = addressId;
    } else if (branchId) {
      specificBranch.branchId = branchId;
    }

    dispatch({
      apiCall: slotExperience.get({
        iHandleStatusCodes: true,
        slotsPage,
        slotType,
        ...specificBranch,
      }),
      types: SLOT_EXPERIENCE.triplet,
    });
  };
