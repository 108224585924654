import { SlotDaysResponse } from 'api/definitions/slotdays/types';
import { Slot } from 'redux/modules/slots/state/initial-state';

export const flattenSlots = (data: SlotDaysResponse, branchId: string) =>
  data.slotDays.content.reduce<Slot[]>((transformedSlots, slotDay) => {
    slotDay.slots.forEach(slot => {
      transformedSlots.push({
        date: slotDay.date,
        ...slot,
        branchId,
        slotRangeCopy: '',
      });
    });
    return transformedSlots;
  }, []);
