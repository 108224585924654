import slotdates from 'api/definitions/slotdates';
import { SlotDatesResponse } from 'api/definitions/slotdates/types';

import { SlotTypes } from 'constants/fulfilmentTypes';

import { SLOT_DATES_REQUEST_ACTIONS } from 'redux/modules/slot-dates/actions/types';
import { SET_SLOTS_DISABLED } from 'redux/modules/slots/actions/types';
import { mapSeasonalRanges } from 'redux/modules/slot-dates/utils/mapSeasonalRanges';
import { customerSlotSelector, hasBookedSlot } from 'redux/modules/customer-slot/selectors';
import { setSelectedSlotGridType } from 'redux/modules/slot-booking/actions/set-selected-slot-grid-type';

export const getSlotDates =
  (
    branchId: string,
    addressId: string,
    fromDate: string,
    slotType: SlotTypes = SlotTypes.DELIVERY,
    customerOrderId = -1,
    size = 93,
  ) =>
  async (dispatch: WtrDispatch, getState: () => WtrState) => {
    try {
      dispatch({
        type: SLOT_DATES_REQUEST_ACTIONS.request,
      });

      const response: { data: SlotDatesResponse; errors: unknown } = await slotdates.post({
        ...(slotType === SlotTypes.DELIVERY ? { addressId } : { branchId }),
        slotType,
        customerOrderId,
        fromDate,
        size,
      })({
        dispatch,
        getState,
      });

      const { data, errors } = response;

      if (errors || !data?.slotDatesOnly) {
        return dispatch({
          type: SLOT_DATES_REQUEST_ACTIONS.failure,
          payload: 'Graph returned slot dates error',
        });
      }

      if (Array.isArray(data.slotDatesOnly?.failures) && data.slotDatesOnly?.failures.length) {
        const { failures } = data.slotDatesOnly;
        if (
          failures.some(({ type }: { message: string; type: string }) => type === 'API_UNAVAILABLE')
        ) {
          return dispatch({
            type: SET_SLOTS_DISABLED,
          });
        }
        dispatch({
          type: SLOT_DATES_REQUEST_ACTIONS.failure,
          payload: 'Slot dates call to graph returned failures',
        });
      }

      const state = getState();
      const slotTimes = data.filedSlotTimesV2?.timesByGrids;
      const customerSlot = customerSlotSelector(state);

      if (
        hasBookedSlot(state) &&
        slotTimes.find(time => time.slotGridType === customerSlot.slotGridType)
      ) {
        dispatch(setSelectedSlotGridType(customerSlot.slotGridType));
      }

      return dispatch({
        type: SLOT_DATES_REQUEST_ACTIONS.success,
        payload: {
          campaigns: mapSeasonalRanges(data.campaigns),
          isPriority: data.slotPriority?.prioritySlotBookingAllowed || false,
          slotDates: data.slotDatesOnly?.content,
          slotTimes,
        },
      });
    } catch (e: any) {
      return dispatch({
        type: SLOT_DATES_REQUEST_ACTIONS.failure,
        payload: e.message,
      });
    }
  };
