import { getSelectedSlotGridType } from 'redux/modules/slot-booking/selectors/get-selected-slot-grid-type';
import { getSlots } from 'redux/modules/slots/actions/get-slots';
import { resetCarouselStartPosition } from 'redux/modules/slot-booking/actions/reset-carousel-start-position';
import { getAvailableSlotsCount } from 'redux/modules/slot-booking/selectors/get-available-slots-count';
import { getSelectedAddress } from 'redux/modules/slot-booking/selectors/get-selected-address';
import { getFulfilmentType } from 'redux/modules/slot-booking/selectors/get-fulfilment-type';
import { pushSlotResultsToGTMDataLayer } from 'utils/gtm';
import { getMinSlotCharge } from 'redux/modules/slots/selectors/get-min-slot-charge';
import { getMaxSlotCharge } from 'redux/modules/slots/selectors/get-max-slot-charge';
import { getSelectedBranchSelector } from 'redux/modules/slot-booking/selectors/get-selected-branch';
import { getSelectedDate } from 'redux/modules/slot-booking/selectors/get-selected-date';
import {
  getLastSlotDay,
  hasSelectedSlotGridType,
  areDatesCampaignDatesOnly,
} from 'redux/modules/slot-dates/selectors';
import { SlotGridType } from 'api/definitions/slotdates/types';
import { getCampaignByDate } from 'redux/modules/slot-dates/selectors/campaigns';
import { saverSlotsUnavailableOnChristmasModal } from 'redux/modules/slot-booking/actions/saver-slots-unavailable-on-christmas-modal';
import { isAfter } from 'utils/date';
import { getCustomerId } from 'redux/modules/sessions/selectors';
import { getGreenSlotsVariant } from 'redux/modules/slots/selectors/get-green-slots-variant';
import { getGreenSlotsCount } from 'redux/modules/slots/selectors/get-green-slots-count';

export const loadSlots = () => async (dispatch: WtrDispatch, getState: () => WtrState) => {
  let state = getState();

  const fulfilmentType = getFulfilmentType();
  const fromDate = getSelectedDate(state);
  const { id: branchId } = getSelectedBranchSelector(state);
  const { id: addressId, postalCode: postcode } = getSelectedAddress(state) || {};
  const size = 5;

  const selectedSlotGridType = getSelectedSlotGridType(state);
  let slotGridType = hasSelectedSlotGridType(state)
    ? selectedSlotGridType
    : SlotGridType.DEFAULT_GRID;

  const activeCampaign = getCampaignByDate(state, fromDate, true);
  const isOutsideSlotHorizon = isAfter(fromDate, getLastSlotDay(state) as string);
  const onlyCampaignDatesAvailable = areDatesCampaignDatesOnly(state);

  if (
    activeCampaign &&
    (isOutsideSlotHorizon || onlyCampaignDatesAvailable) &&
    slotGridType === SlotGridType.SAVER_GRID
  ) {
    slotGridType = SlotGridType.DEFAULT_GRID;
    await dispatch(saverSlotsUnavailableOnChristmasModal(slotGridType));
  }

  if (fulfilmentType) {
    await dispatch(
      getSlots({
        fulfilmentType,
        postcode,
        addressId,
        branchId,
        fromDate,
        size,
        slotGridType,
      }),
    );

    dispatch(resetCarouselStartPosition());

    state = getState();
    const maxCharge = getMaxSlotCharge(state);
    const minCharge = getMinSlotCharge(state);
    const availableSlots = getAvailableSlotsCount(state);
    const greenSlotsCount = getGreenSlotsCount(state);

    const greenSlotVariant = getGreenSlotsVariant(state);

    pushSlotResultsToGTMDataLayer({
      postcode,
      branchId,
      availableSlots,
      greenSlotsCount,
      fulfilmentType: fulfilmentType?.gtmEventCategory,
      maxCharge,
      minCharge,
      customerId: getCustomerId(state),
      greenSlotVariant,
    });
  }
};
