import { addDays, isWithinRange } from 'utils/date';
import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';
import { getSelectedDate } from 'redux/modules/slot-booking/selectors/get-selected-date';
// TODO: [SSR][WARN] Does this impact SSR? I believe SSR could report incorrectly if this is triggered on server
// eslint-disable-next-line no-restricted-imports
import { mediaBreakpointDown } from 'utils/mediaQueries';

export const getGreenSlotsCount = (state: WtrState) => {
  const { slots } = getStateAtNamespaceKey(state, 'slots');
  const selectedDate = getSelectedDate(state);
  const gridRange = mediaBreakpointDown('md') ? 2 : 4;
  const greenSlotsOnVisibleGrid = slots.filter(
    slot =>
      slot.greenSlot && isWithinRange(slot.date, selectedDate, addDays(selectedDate, gridRange)),
  );
  return greenSlotsOnVisibleGrid.length;
};
