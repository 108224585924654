import { getSlots } from 'redux/modules/slots/selectors';
import { getSelectedDate } from 'redux/modules/slot-booking/selectors/get-selected-date';

// TODO: [SSR][WARN] Does this impact SSR? I believe SSR could report incorrectly if this is triggered on server
// eslint-disable-next-line no-restricted-imports
import { mediaBreakpointDown } from 'utils/mediaQueries';

import { addDays, isWithinRange } from 'utils/date';

export const getAvailableSlotsCount = state => {
  const slots = getSlots(state);
  const selectedDate = getSelectedDate(state);
  const gridRange = mediaBreakpointDown('md') ? 2 : 4;
  const availableSlotsOnVisibleGrid = slots.filter(
    slot =>
      slot.slotStatus === 'AVAILABLE' &&
      isWithinRange(slot.date, selectedDate, addDays(selectedDate, gridRange)),
  );
  return availableSlotsOnVisibleGrid.length;
};
