import { getFirstSlotDay, getLastSlotDay } from 'redux/modules/slot-dates/selectors';
import { getCampaigns } from 'redux/modules/slot-dates/selectors/campaigns';
import { FORMAT, dayjs } from 'utils/date';
import { getSelectedBranchSelector } from 'redux/modules/slot-booking/selectors/get-selected-branch';
import { customerSlotSelector, hasBookedSlot } from 'redux/modules/customer-slot/selectors';
import {
  getFulfilmentType,
  getPathName,
} from 'redux/modules/slot-booking/selectors/get-fulfilment-type';
import { getSelectedAddress } from 'redux/modules/slot-booking/selectors/get-selected-address';

// TODO: [SSR][WARN] Does this impact SSR? This fires on load so could impact SSR. Related to finding an alternative to detecting grid length
// eslint-disable-next-line no-restricted-imports
import { mediaBreakpointDown } from 'utils/mediaQueries';

const getSeasonalStartDateFromURL = state => {
  const pathname = getPathName();
  const campaigns = getCampaigns(state);

  if (campaigns) {
    const key = Object.keys(campaigns).filter(rangeKey =>
      pathname.toLowerCase().includes(rangeKey.toLowerCase()),
    );

    if (key && campaigns[key]) {
      return dayjs(campaigns[key].seasonalStartDate);
    }
  }
  return undefined;
};

const isBookedSlotMatchesSelectedAddressAndFulfilmentType = state => {
  if (!hasBookedSlot(state)) {
    return false;
  }
  const customerSlot = customerSlotSelector(state);
  const fulfilmentType = getFulfilmentType(state);
  const selectedBranch = getSelectedBranchSelector(state);
  const selectedAddressIsSameAsBookedSlot =
    fulfilmentType.category === 'COLLECTION' ||
    ((getSelectedAddress(state) || {}).id || '-1') === customerSlot.addressId;
  const selectedBranchIsSameAsBookedSlot =
    selectedBranch.id === customerSlot.branchId ||
    (selectedBranch.branchRefurbish &&
      selectedBranch.branchRefurbish.replacementBranch &&
      selectedBranch.branchRefurbish.replacementBranch === customerSlot.branchId);
  const fulfilmentTypeOfBookedSlotMatchesPage = customerSlot.slotType === fulfilmentType.slotType;
  return (
    selectedAddressIsSameAsBookedSlot &&
    selectedBranchIsSameAsBookedSlot &&
    fulfilmentTypeOfBookedSlotMatchesPage
  );
};

export const getDefaultCarouselDate = state => {
  const seasonalDate = getSeasonalStartDateFromURL(state);
  const firstSlotDay = getFirstSlotDay(state);
  const startDate = dayjs(firstSlotDay || new Date());

  let carouselDate = startDate;
  if (isBookedSlotMatchesSelectedAddressAndFulfilmentType(state)) {
    const gridOffset = mediaBreakpointDown('md') ? -2 : -4;
    const customerSlot = dayjs(customerSlotSelector(state).slotDate);

    const endOfRange = dayjs(getLastSlotDay(state));
    const latestCarouselDate = endOfRange.add(gridOffset, 'day');
    const startOfRange = dayjs(latestCarouselDate).add(1, 'day');
    const isEndOfSlotsRange = dayjs(customerSlot).isBetween(startOfRange, endOfRange, 'day', '[]');

    carouselDate = isEndOfSlotsRange ? latestCarouselDate : customerSlot;
  }
  if (seasonalDate && seasonalDate.isSameOrAfter(startDate)) {
    carouselDate = seasonalDate;
  }
  return carouselDate.format(FORMAT.ISO8601_DATE);
};
